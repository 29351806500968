@import 'variables';

.all-chat-section {
  height: 100%;

  .all-chat-row {
    flex: 1;

    .chat-pool-card {
      // position: absolute;
      bottom: 0;
      width: 100%;
      border-bottom-left-radius: 30px;
      left: 0;
      z-index: 2;

      .according-text {
        padding: 0px;
        padding-left: 0px !important;

        .chat-pool-accord {
          padding: 15px;
        }
      }

      .collapse,
      .collapsing {
        background-color: #f4f6f8;
        padding: 0 10px 10px 14px;
      }
    }
  }
}

.card-rounded {
  padding-left: 8px;
  border-radius: 4px; // UIUX-1733 Changed from 30px to 0px

  .col-md-5 {
    padding-right: 10px;
  }

  .messaging-chats {
    margin-right: 7px;
    .card {
      border: 0px;
      background-color: transparent;

      .according-text {
        border-bottom: 0px;
        background-color: transparent;
        color: #000;
        font-weight: 700;
        padding-left: 10px;
        cursor: pointer;

        .tab-title {
          color: $darkgray-2;
          font-size: 15px;
          line-height: 18px;
          text-align: left;
          font-weight: bold;
        }

        .chat-pool-accord {
          background-color: #f4f6f8;

          .chat-pool {
            color: #919eab;
            font-size: 13px;
            line-height: 16px;
            width: 71px;
            text-align: left;
            font-weight: 500;
          }
        }
      }

      .card-body {
        padding: 0px;

        .list-group {
          .list-group-item {
            padding: 7px;
          }
        }
      }
    }

    .chat-list {
      .active-list {
        padding: 0.75rem 0.25rem;
        border-radius: 8px !important;
        background-color: var(--primary-4);
        box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08),
          0 1px 3px 0 rgba(0, 0, 0, 0.16);
        margin-bottom: 5px;

        .chatname {
          color: var(--primary-2);
          font-size: 12px;
          line-height: 18px;
          text-align: left;

          svg {
            background-color: var(--primary-2);
            color: #ececfd;
            border-radius: 100%;
            width: 18px;
            height: 18px;
            padding: 3px;
          }

          .chatname-title {
            color: $darkgray-1;
            font-size: 15px;
            font-weight: bold;
            line-height: 18px;
            text-align: left;
            // padding-left: 5px;
          }
        }

        .message {
          color: $darkgray-2;
          font-size: 13px;
          line-height: 16px;
          text-align: left;
          // padding-top: 5px;
        }

        .date-time {
          color: $darkgray-2;
          font-size: 11px;
          line-height: 14px;
          text-align: right;
          // padding-top: 5px;
        }
      }

      .non-active-list {
        border: 0 !important;
        border-bottom: 1px solid #f4f6f8;

        .chatname {
          color: #454f5b;
          font-size: 12px;
          line-height: 18px;
          text-align: left;

          svg {
            background-color: #ececfd;
            color: var(--primary-2);
            border-radius: 100%;
            width: 18px;
            height: 18px;
            padding: 3px;
          }

          .chatname-title {
            font-family: 'Lato';
            color: $darkgray-1;
            font-size: 15px;
            font-weight: bold;
            line-height: 18px;
            text-align: left;

            // padding-left: 5px;
          }
        }

        .message {
          color: $darkgray-2;
          font-size: 13px;
          line-height: 16px;
          text-align: left;
          // padding-top: 5px;
        }

        .date-time {
          color: $darkgray-2;
          font-size: 11px;
          line-height: 14px;
          text-align: right;
          // padding-top: 5px;
        }
      }

      .list-group-item {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .bottom-sheet-container {
    height: 48px;
    .bottom-sheet-main {
      border-bottom-left-radius: 4px; // UIUX-1733
      z-index: 9;
      background-color: #f4f6f8;
      position: absolute;
      bottom: 0;
      width: 100%;
      border-top: 1px solid var(--primary);
      height: 48px;
    }
  }

  h5.title {
    padding-left: 10px;
    padding-top: 15px;
    color: var(--primary);
    font-size: 1.25rem;
    font-weight: 500;
  }

  .card-header-tabs {
    margin: 0px auto;
    background-color: #f4f6f8;
  }

  .tab-content,
  .user-details-wrap {
    .userdetails-lead-tabs {
      background-color: $lightgray-2;

      .card-header-tabs {
        margin-bottom: 0 !important;
      }
    }

    .user-avatar {
      background-color: #ffffff;
      border-radius: 8px; //UIUX-1748
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.16),
        0 1px 3px 0 rgba(0, 0, 0, 0.24);
      width: 104px;
      height: 104px;
      margin-bottom: 20px;
      position: relative;

      .oval {
        position: absolute;
        bottom: -2px;
        right: -2px;
        // background-color: #108043;
        border: 1.33px solid #ffffff;
        border-radius: 100%;
        width: 16px;
        height: 16px;
      }
      .active {
        background-color: #108043;
      }
      .inactive {
        background-color: red;
      }

      svg {
        width: 104px;
        height: 60px;
        color: var(--primary-2);
      }
    }

    .flip-back-container {
      // display: grid;
      // grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      // grid-gap: 10px;
      display: flex;
      justify-content: flex-start;

      .initiation,
      .timers {
        width: 50%;
        h4 {
          font-family: 'Lato', sans-serif;
          color: var(--primary);
          font-size: 17px;
          line-height: 20px;
          text-align: left;
          margin-bottom: 5px;
        }

        .back-detail {
          margin-left: 2px;
          font-size: 13px;
          // margin-bottom: 10px;
          margin-bottom: 2px;
          // display: grid;
          // grid-template-columns: 1fr 1fr;
          // grid-gap: 10px;
          display: flex;
          justify-content: space-between;
          width: 80%;
          text-align: right;
        }
      }
    }

    .flip-back-btn {
      display: block;
      cursor: pointer;
      text-align: center;
    }

    .user-name-address {
      text-align: start;
      margin-left: 1rem;
      h4 {
        font-family: 'Lato', sans-serif;
        color: var(--primary);
        font-size: 17px;
        line-height: 20px;
        // text-align: start;
        margin-bottom: 0px;
        // margin-left: 1rem;
      }

      .user-address {
        // text-align: start;
        // margin-left: 1rem;

        span {
          svg {
            color: $darkgray-2;
            height: 12px;
          }
        }

        span {
          font-family: 'Lato', sans-serif;
          color: $darkgray-2;
          font-size: 13px;
          line-height: 16px;
          text-align: left;
        }

        .more-details {
          color: var(--primary);
          font-family: 'Lato', sans-serif;
          font-size: 13px;
          line-height: 16px;
          text-align: center;
          cursor: pointer;
        }
      }
    }

    .action-btn {
      text-align: center;
      padding-top: 15px;
      padding-bottom: 15px;

      & .btn-secondary {
        background-color: #f4f6f8 !important;
        border-radius: 4px;
        color: $darkgray-2 !important;
        font-size: 13px;
        line-height: 5px;
        border: 0;
        box-shadow: none;
        font-weight: 500;
        margin: 0 1px;

        &.active {
          background-color: #ececfd !important;
          color: var(--primary-2) !important;
        }

        &:active,
        &:focus {
          border: 0;
          box-shadow: none;
        }
      }

      &.bottom-btn-right-panel {
        margin: 0px 5px 12px 0px;
        padding: 8px;
        font-size: 13px;
        font-weight: 500;
        border: 0;
        width: calc(100% - 30px);
        background-color: #f4f6f8;
        border-radius: 8px;

        & .btn-secondary {
          &.active {
            background-color: #fff !important;
            box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08);
          }
        }
      }

      .btn {
        line-height: 1.375rem;

        img,
        svg {
          width: 1.25rem;
          height: 1.25rem;
          margin-top: -3px;
        }

        i {
          font-size: 1.125rem;
        }
      }
    }

    .lead-form-section {
      background-color: #ffffff;
      border-radius: 0px; // UIUX-1733 Changed from 12px to 0px
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
      padding: 20px 20px 20px 20px;
      margin-bottom: 20px;
      margin-right: 10px;
      width: 95%;

      h5 {
        color: var(--primary);
        font-family: 'Lato', sans-serif;
        font-size: 13px;
        line-height: 16px;
        text-align: left;
        font-weight: 600;
      }

      input,
      select {
        background-color: #f4f6f8;
        border-radius: 4px;
        padding: 8px;
        margin-bottom: 4px;
        height: 40px;
        border: 0;
        font-size: 13px;

        &:focus {
          border: 0 !important;
          box-shadow: none !important;
        }
      }

      textarea {
        background-color: #f4f6f8;
        border-radius: 4px;
        border: 0;
        resize: none;
        font-size: 13px;
        height: 80px;

        &:focus {
          border: 0;
          box-shadow: none;
        }
      }

      .lead-form-submit-btns {
        padding-top: 5px;

        .lead-save-draft-btn {
          background-color: transparent;
          border: 0px;
          color: $darkgray-2;
          font-family: 'Lato', sans-serif;
          font-size: 13px;
          line-height: 16px;
          text-align: left;
          font-weight: 500;
        }

        .lead-submit-btn {
          background-color: var(--primary-2);
          border: 0;
          border-radius: 4px;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
          color: #ffffff;
          font-family: 'Lato', sans-serif;
          font-size: 13px;
          line-height: 16px;
          text-align: left;
          padding: 8px 16px;

          span {
            margin-right: 5px;

            svg {
              width: 14px;
              height: 14px;
            }
          }
        }
      }
    }

    #connect-form {
      padding: 20px 20px;

      input {
        background-color: #f4f6f8;
        border-radius: 4px;
      }

      button {
        background-color: var(--primary-2);
        border-color: var(--primary-2);
        border-radius: 4px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08),
          0 1px 2px 0 rgba(0, 0, 0, 0.16);
        color: #ffffff;
        font-family: 'Lato', sans-serif;
        font-size: 13px;
        line-height: 16px;
        text-align: left;
        margin-top: 10px;
        margin-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 4px;
        padding-bottom: 4px;
        float: right;

        span {
          padding-right: 5px;

          svg {
            width: 14px;
            height: 14px;
          }
        }
      }
    }

    .suggestion-input {
      width: calc(100% - 20px);

      & .input-group-prepend {
        height: 32px;

        & span {
          background-color: #ffffff;
          border: 1px solid #c4cdd5;
          border-right: 0;
          padding-right: 0;
        }

        & svg {
          fill: #919eab;
          width: 12px;
        }
      }
      & .input-group-append {
        height: 32px;

        & .input-group-text {
          background-color: #ffffff;
          border: 1px solid #c4cdd5;
          border-left: 0;
          padding-left: 0;
        }

        & svg {
          fill: #919eab;
          width: 12px;
          cursor: pointer;
        }
      }

      & input {
        border: 1px solid #c4cdd5;
        border-left: 0;
        border-right: 0;
        height: 32px;
        font-size: 13px;
        padding-left: 8px;

        &:focus {
          box-shadow: none;
          border: 1px solid #c4cdd5;
          border-left: 0;
          border-right: 0;
        }
      }
    }
  }
}

.chat-details {
  padding-left: 0px;

  .previous-conversation{
    position: absolute; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 15px;
    width: 238px;
    z-index: 1;
  }
  .chat_box {
    // height: calc(100% - 100px);
    // overflow-x: hidden;
    // overflow-y: auto;
    .chat-date-time {
      color: #566a8a;
      font-size: 14px;
      line-height: 17px;
      position: relative;
      top: -30px;
      background: #FAFDFD;
      padding: 5px 18px;
      border: 1px solid #DFE3E8;
      border-radius: 4px;
    }
    .icon-button {
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 17px;
      padding: 2px 6px;
      border: 1px solid #DFE3E8;
      background: #FAFDFD;

      &.prev-chat{
        border-right: none;
        border-radius: 4px 0px 0px 4px !important;
      }
    }
    .date-chip{
      color: #566a8a;
      font-size: 14px;
      line-height: 17px;
      background: #FAFDFD;
      padding: 5px 10px;
      border: 1px solid #DFE3E8;
      border-radius: 4px;
      display: flex;
      align-items: center;

      &.prev-chat{
        border-left: none;
        border-radius: 0px 4px 4px 0px !important;
      }
    }
    .chat-date-row{
      text-align: center;
      padding-top: 10px;
      padding-bottom: 8px;
    }

    .chat_message_wrapper {
      &::after {
        clear: both;
        content: ' ';
        display: table;
      }

      &::before {
        content: ' ';
        display: table;
      }

      .chat_user_avatar {
        float: left;
        background-color: #ececfd;
        border-radius: 100%;
        position: relative;

        svg {
          width: 28px;
          height: 28px;
          padding: 5px;
          color: var(--primary-2);
        }
      }

      margin-bottom: 15px;

      .chat_message {
        list-style: outside none none;
        padding: 0;
        float: left;
        margin: 0 0 0 20px;
        max-width: 77%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        > li,
        .chat-message-text {
          list-style: outside none none;
          clear: both;
          color: var(--primary-2);
          display: block;
          float: left;
          font-size: 15px;
          padding: 8px 16px;
          text-align: left;
          line-height: 18px;
          position: relative;
          background: #ececfd none repeat scroll 0 0;
          margin-bottom: 10px;
          border-radius: 22px;

          p {
            margin: 0;
            padding: 5px;
          }
        }

        .chat_message_time {
          color: #727272;
          display: block;
          font-size: 11px;
          margin-top: -8px;
          text-align: left;
          padding-left: 15px;
          min-width: 200px;
        }
      }

      &.chat_message_right .chat_message {
        align-items: flex-end;
      }
    }

    .chat_message_wrapper.chat_message_right {
      .chat_user_avatar {
        float: right;
        background: $lightgray-2;
        svg {
          color: $darkgray-1;
        }
      }

      .chat_message {
        float: right;
        margin-left: 0 !important;
        margin-right: 24px !important;

        > li,
        > .chat-message-text {
          &:first-child {
            &::before {
              border-color: transparent transparent transparent #ededed;
              border-width: 0 0 29px 29px;
              left: auto;
              right: -14px;
            }
          }

          background-color: #f4f6f8;
          color: #454f5b;
          font-size: 15px;
          line-height: 18px;
          text-align: left;
        }

        .chat_message_time {
          color: #727272;
          display: block;
          font-size: 11px;
          padding-top: 2px;
          text-align: right;
          padding-right: 15px;
        }
      }
    }
  }

  .md-user-image {
    border-radius: 50%;
    width: 34px;
  }

  img {
    border: 0 none;
    box-sizing: border-box;
    height: auto;
    max-width: 100%;
    vertical-align: middle;
  }

  .chat-details-card {
    background-color: #ffffff;
    // border-top-right-radius: 28px; // UIUX-1733
    // border-bottom-right-radius: 28px;
    height: 100%;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08);

    &.chat-ended {
      opacity: 0.6;
      pointer-events: none;

      .chat-message-input {
        background-color: $lightgray-1;
      }

      .chat-message-ended {
        .chat_message > li,
        .chat_message > .chat-message-text {
          background-color: $danger-4;
          color: $danger-2;
        }
      }
    }

    .card-header {
      // background-color: transparent;
      border-radius: 0px; // UIUX-1733 Changed from 0 28px 0 0 to 0px
      text-align: left;
      z-index: 999;
      box-shadow: 0 2px 4px rgba($darkgray-1, 0.2);

      .chat-details-header {
        color: $darkgray-2;
        line-height: 30px;

        .chat-back-arrow {
          float: left;
        }

        .chat-suggestion-btn {
          float: right;
        }
      }
    }
  }

  .transferred-chat {
    background-color: #f9fafb;
    text-align: center;
    padding: 12px;
    color: $darkgray-2;
    font-size: 15px;
    line-height: 18px;
    box-shadow: inset 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
  }
}

.chat-suggestion-header {
  .suggestion-header {
    background-color: transparent;

    .chat-details-header {
      color: $darkgray-2;
      font-size: 15px;
      line-height: 30px;
      text-align: center;

      .chat-back-arrow {
        float: left;
      }

      .chat-suggestion-btn {
        float: right;
      }
    }
  }

  .tab-content {
    .active {
      display: flex;
    }

    .userdetails-lead-tabs {
      width: 100%;

      .tab-right-panel {
        background-color: transparent;
        margin: 0 0 0px -8px !important;
      }
    }
  }
}

.visitor-text {
  background-color: #fcebdb;
  text-align: center;
  padding: 12px;
  color: #c05717;
  font-size: 15px;
  margin-top: -20px;
  width: 100%;
  margin-left: -8px;
  margin-bottom: 20px;
}

.suggestions-questions {
  padding-bottom: 20px;
  padding-right: 10px;
  width: 100%;

  & .card {
    border: 0;
    // background: #f9fafb;
    box-shadow: none;

    & .card-body {
      padding: 0px 30px;
    }

    & .chat-list div {
      padding: 3px;
    }

    & .collapse {
      background: #f9fafb;
    }

    & div {
      box-shadow: none;
      // background: #f9fafb;
      border: 0;
    }
  }

  .tab-icon {
    svg {
      color: #454f5b;
      width: 16px;
      height: 14px;
    }
  }

  .tab-title {
    margin-left: 5px;
    color: $darkgray-2;
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    line-height: 16px;
    text-align: left;
  }

  .chat-list {
    .chatname {
      .chatname-title {
        color: $darkgray-2;
        font-family: 'Lato', sans-serif;
        font-size: 12px;
        line-height: 16px;
        width: 254px;
        text-align: left;
        // padding-left: 3px;
      }
    }
  }
}

#chat {
  flex: 1;

  & .chat-message-input {
    position: absolute;
    bottom: 0;
    border: 1px solid #c4cdd5;
    rder-radius: 4px; // UIUX-1733
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px;
    width: calc(100% - 20px);
    justify-content: space-between;
    padding-right: 10px;

    .submit-icon {
      margin: 0px 5px;
    }

    & .input-action-icon {
      & span {
        margin: 0px 5px;
      }

      & svg {
        cursor: pointer;
      }
    }

    & textarea {
      resize: none;
    }

    & input,
    & textarea {
      width: calc(100% - 102px);
      border: 0;
      border-radius: 12px;

      &:focus {
        border: 0;
        box-shadow: none;
      }
    }
  }
}

.grey-card-panel {
  background-color: #f9fafb;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08);
  height: calc(100vh - 98px);
  overflow-y: auto;

  & .tab-right-panel {
    display: flex;
    justify-content: center;
    margin: 0 0 20px -8px !important;
    padding: 20px 0;
    background: none;
    line-height: 15px;
    background-color: #f4f6f8;
   // border-top-left-radius: 28px; //UIUX-1733 Edgy Corners
    // border-top-right-radius: 28px;

    & .nav-link {
      margin: 0px 5px;
      padding: 0.7rem 2rem;
      font-size: 13px;
      font-weight: 500;
      border: 0;

      &:hover,
      &:focus {
        box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08);
        
        color: var(--primary-2);
        font-size: 13px;
        background-color: #ffffff;
      }
    }

    & .active {
      box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08);
       border-radius: 3px; // UIUX-1733
      color: var(--primary-2);
    }
  }
}

.container-full-height {
  height: 100%;
  padding-left: 10px;
  padding-top: 10px;

  & .msg-calc-height {
    height: 100%;
  }
}

@media (max-width: 767px) {
  .all-chat-section .all-chat-row .chat-pool-card {
    width: 100% !important;
    border-bottom-right-radius: 30px; // UIUX-1733 Changed from 30px to 0px
  }

  .chat-details .chat-details-card {
    border-radius: 30px;
  }

  #content {
    padding-left: 10px;

    & > .rounded-inner {
      border-radius: 0;
    }
  }

  #sidebar .navbar-ham {
    left: calc(110% + 0.2rem);
    top: 3.3rem;
  }
}

.copied-to-clipboard-success {
  position: relative;
  margin-right: -62px;
  margin-left: 0.5rem;
  visibility: hidden;
  opacity: 0;
  transition: all ease 0.3s;

  &.active {
    opacity: 1;
    visibility: visible;
    margin-right: -0.5rem;
  }
}

.messaging-mobile {
  .chat-list-wrapper {
    @media (min-width: 768px) {
      width: 100%;
    }

    &.border-right {
      @media (max-width: 767px) {
        border-right: 0 !important;
      }
    }
  }

  .all-chat-section .all-chat-row .chat-pool-card {
    border-radius: 0 0 0.75rem 0.75rem;
  }

  .col-chat-wrap,
  .chat-details-card,
  .bottom-sheet-container .card-rounded {
    border-radius: 0.75rem;

    .card-header {
      padding: 0.5rem;
      border-radius: 4px 4px 0 0;
    }
  }

  .chat-details {
    &-header {
      &-right {
        // width: 321px;
        justify-content: end;

        .btn-end-chat {
          padding: 2px 4px;
          // border: 0;
          font-size: 0.65rem;
        }
        .chat-close-btn {
          font-size: 0.65rem;
          // padding: 0px 6px;
          .cross-icon {
            font-size: 16px;
          }
        }
      }

      &-left {
        // width: 100%;

        .chat-details-user-info {
          float: left;
          width: calc(100% - 28px);
        }
      }

      .chat-header-visitor-name {
        font-size: 0.875rem;
        line-height: 1.25;
        margin-bottom: 0.25rem;
      }

      .chat-header-start-time {
        line-height: 1.25;
      }
    }

    .chat_box {
      .chat_message_wrapper {
        .chat_message {
          margin-left: 0.5rem;

          .chat-message-text {
            border-radius: 0.75rem;

            p {
              font-size: 0.875rem;
            }
          }
        }

        &.chat_message_right {
          .chat_message {
            margin-right: 0.5rem !important;
          }
        }
      }
    }
  }

  .right-pane-wrapper {
    margin-left: 0.25rem !important;
  }

  .input-action-icon.emojis-wrap {
    margin-left: 0;
  }

  #chat .chat-message-input .submit-icon {
    margin-right: 0;
    padding: 1px 0px 0px 0px !important;
    border-radius: 2rem;
    width: 36px;
    height: 36px;
    line-height: 2.375;
  }

  .chat-input-actions {
    align-items: center;
  }

  #chat .chat-message-input {
    border-radius: 0;
    border-width: 1px 0 0 0;
    margin-bottom: 0;
    padding: 0.5rem 0;
  }

  .chat-details-right-pane {
    .close-panel {
      left: 8px;
      top: 11px;
    }
  }
}

.chat-disabled {
  opacity: 0.6;
  pointer-events: none;
}
.chat-message-ended {
  .chat_message > li,
  .chat_message > .chat-message-text {
    background-color: $danger-4 !important;
    color: $danger-2 !important;
  }
}
