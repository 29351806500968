@import "/src/assets/app-styles/scss/variables";


.date-range-container{
    .input-group-container{
        margin-bottom: 5px !important;
        margin-left: 5px;
        margin-right: 5px;
        &:hover{
            input,.input-end{
                border-color: var(--primary) !important;
            }
        }
        input, .input-end{
            height: 30px;
            background-color: white !important;
            color: #99a1aa;
            font-size: 14px;
        }
        input{
            border-right: none !important;
            border-top-left-radius: 4px !important;
            border-bottom-left-radius: 4px !important;
            &:focus +.input-end{
                border-color: var(--primary) !important;
            }
        }
        .input-end{
            height: 30px;
            border-left: none !important;
            svg{
                width:20px !important;
                height:20px !important;
            }
        }
    }

    .dropdown-btn{
        padding: 6px !important;
        width: 228px;
        .small-text{
            font-size: x-small;
        }
        .date-range{
            font-size: 14px;
            font-weight: 600;
            line-height: normal;
        }
    }

    .date-range-wrapper{
        max-height: calc(100vh - 240px);
        overflow: auto;
        // max-width: 564px;

        background-color: white;
        position: absolute;
        z-index: 1;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
          rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

        /* width */
        &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }
        
        /* Track */
        &::-webkit-scrollbar-track {
            background: var(--lightgray-2);
            border-radius: 10px;
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: var(--lightgray-4);
            border-radius: 6px;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb {
            background: $lightgray-4;
        }  
        .date-dropdown-wrapper {
            .rdrStaticRangeSelected, .rdrStartEdge, .rdrInRange, .rdrEndEdge, .rdrDayToday{
                color:var(--primary-2) !important;
            }
            .rdrDayToday .rdrDayNumber span:after {
                background: var(--primary) !important;
                }
            .rdrDateDisplayItemActive{
                color: var(--primary) !important;
            }
        }
        .action-buttons{
            display: flex;
            align-items: center;
            justify-content: end;
            margin: 10px;
            margin-top: 0px;
        }
    }
}


.date-range-wrapper{
    max-height: calc(100vh - 250px);
    overflow: auto;
    // max-width: 564px;

    background-color: white;
    position: absolute;
    z-index: 1;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

    /* width */
    &::-webkit-scrollbar {
        width: 6px;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
        background: var(--lightgray-2);
        border-radius: 10px;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: var(--lightgray-4);
        border-radius: 6px;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb {
        background: $lightgray-4;
    }  
    .date-dropdown-wrapper {
        .rdrStaticRangeSelected, .rdrStartEdge, .rdrInRange, .rdrEndEdge{
            color:var(--primary-2) !important;
        }
    }
    .action-buttons{
        display: flex;
        align-items: center;
        justify-content: end;
        margin: 10px;
        margin-top: 0px;
    }
}
.rdrMonthAndYearWrapper{
    height: inherit!important;
}
.rdrStaticRangeLabel{
    padding: 8px 20px;
}
.rdrMonthName{
    display: none;
}
.rdrDefinedRangesWrapper{
    width: 155px;
}